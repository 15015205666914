import DeviceDetailGraph from "./DeviceDetailGraph";

export default function TemperatureSkinGraph(props) {
    const data = props.data || [];
    const backgroundColor = props.backgroundColor || "rgba(153, 102, 255, 0.2)";
    const borderColor = props.borderColor || "rgba(153, 102, 255, 1)";
    const celsius = props.celsius || false;

    return (
        <DeviceDetailGraph
            data={data}
            dataType={"stemp"}
            label={"Skin Temp."}
            celsius={celsius}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        />
    );
}

import DeviceDetailGraph from "./DeviceDetailGraph";

export default function HeartRateGraph(props) {
    const data = props.data || [];
    const backgroundColor = props.backgroundColor || "rgba(255, 99, 132, 0.2)";
    const borderColor = props.borderColor || "rgba(255, 99, 132, 1)";

    return (
        <DeviceDetailGraph
            data={data}
            dataType={"hr"}
            label={"Heart Rate"}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        />
    );
}

import DeviceDetailGraph from "./DeviceDetailGraph";

export default function TemperatureCoreGraph(props) {
    const data = props.data || [];
    const backgroundColor = props.backgroundColor || "rgba(75, 192, 192, 0.2)";
    const borderColor = props.borderColor || "rgba(75, 192, 192, 1)";
    const celsius = props.celsius ?? false;

    return (
        <DeviceDetailGraph
            data={data}
            dataType={"ctemp"}
            label={"Est. Core Temp."}
            celsius={celsius}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        />
    );
}

import DeviceDetailGraph from "./DeviceDetailGraph";

export default function HeatStressIndexGraph(props) {
    const data = props.data || [];
    const backgroundColor = props.backgroundColor || "rgba(255, 206, 86, 0.2)";
    const borderColor = props.borderColor || "rgba(255, 206, 86, 1)";

    return (
        <DeviceDetailGraph
            data={data}
            dataType={"hsi"}
            label={"Heat Stress Index (HSI)"}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        />
    );
}
